.contact-history {
    position: relative;

    .contact-button-container {
        top: 17px;
        left: 300px;
        display: flex;
        position: absolute;

        .contact-button{
            margin-left: 1rem;
         }
     
         .contact-gray-btn {
             .heystack_btn {
                 color: var(--black);
                 background: var(--gray3);
             }
         }
    }

    .contact-list-container {
        padding: 2rem 0;
    }
}

.contact-detail {
    .form-btn {
        right: 2.5%;
        display: flex;
        bottom: 2.5rem;
        position: absolute;

        .contact-back-btn {
            margin-right: 1rem;

            .heystack_btn {
                color: var(--black);
                background: var(--gray3);
            }
        }
    }
}