.post-wrapper {
    display: flex;
    align-items: right;
}

.post-item {
    display: flex;
    padding: 1rem 0;
    justify-content: space-between;
    box-sizing: border-box;
}

.post-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 1rem;
}

.post-content {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
}

.post-title {
    margin: 0;
}

.post-description {
    margin: 0;
}

.icons-container {
    min-width: 200px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    padding: 10px;
    white-space: nowrap;

    .icon {
        margin-right: 20%;
    }
    
    .icon-view-container{
        width: 80%;
        display: flex;
        cursor: pointer;
        margin: 0.5rem 0;
        justify-content: space-between;
    }

    .edit-link {
        white-space: nowrap;
        color: var(--orange3);
    }

    .delete-link {
        color: var(--black);
    }
}