.listCard {
    display: flex;
    border-radius: 5px;
    padding: 0.75rem 2rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 0.2px solid #d7d7d7;
    justify-content: space-between;

    .list-text-container {
        display: flex;
    }

    .text-container {
        margin-left: 1rem;
    }

    .list-header {
        font-weight: 500;
        color: var(--black);
        margin-bottom: 0.25rem;
    }

    .list-text {
        margin: 0;
        font-size: 15px;
        color: var(--black);
    }

    .list-icon-container {
        display: flex;
        color: var(--black);
        text-decoration: none;
    }

    .list-icon,
    .list-icon-text {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .list-icon-text {
        margin: 0 0 0 1rem;
    }
}