.login {
    display: flex;
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    max-width: -webkit-fill-available !important;

    ::placeholder {
        width: 100px;
        color: #BBBBBB;
        overflow: visible;
    }
    
    .login-label {
        margin-bottom: 10px;
    }
    
    .headStyle {
        font-weight: 550;
        font-size: 35px;
    }
    
    .left-side {
        flex: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF4E3
    }
    
    .logo-container img {
        width: 400px;
    }
    
    .right-side {
        flex: 60%;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login-form {
        max-width: 300px;
        width: 100%;
    }

    .login-input {
        background-color: #EBEBEB;
        box-sizing: border-box;
        border: none;
        outline: none;
        width: 100%;
        padding: 8px 16px;
        margin-top: -16px;
        margin-bottom: 16px;
        border-radius: 5px;
    }

    .heystack_btn {
        margin-top: 20px;
        position: absolute;
    }
}