@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

.App {
    display: flex;
}

.content {
    padding: 10px;
    width: -webkit-fill-available;
}

.section {
    padding: 1.5rem 2.5% !important;
}

.login-page {
    padding: 0;
}