.heystack-blogCard {
    cursor: pointer;

    .card {
        height: 100%;
    }
    
    .card-img-top{
        object-fit: cover;
        max-height: 300px;
    }

    .card-body {
        text-align: justify;
    }

    .card-subTitle {
        margin: 0;
    }
}