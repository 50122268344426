body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #E7BE82;
  --secondary1: #151E3F;
  --secondary2: #030027;

  --orange1: #F6DEBB;
  --orange2: #F0D3AA;
  --orange3: #E7BE82;

  --gray: #DCDCDC;
  --gray1: #FAFAFA;
  --gray2: #EBEBEB;
  --gray3: #E5E5E5;
  --gray4: #707070;
  --gray5: #f8f8f8;

  --black: #343434;
  --white: #FAFAFA;
}

body {
  min-width: 100vh;
  position: relative;
  background-color: var(--gray1) !important;
}

h1 {
  font-size: 66px;
  font-weight: 600;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
}

.heystack-button {
  position: relative;
  text-align: center;
  padding: 10px 20px !important;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}


/* Form Buttons */
.heystack_formBtn {
  border: none;
  padding: 16px 24px;
  border-radius: 3px;
  width: fit-content;
  background: var(--primary);
  color: var(--black);
}

.heystack_formBtn:hover {
  text-decoration: none;
}