.confirmation-model {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-Items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);

    &.hidden {
        display: none;
    }

    .model-container {
        padding: 2rem;
        display: flex;
        min-width: 300px;
        border-radius: 2rem;
        justify-content: space-between;
        background-color: var(--white);

        .model-content{
            width: -webkit-fill-available;
        }

        .model-close-icon {
            height: fit-content;
        }

        .form-btn a {
            display: block;
            margin-left: auto;
        }

        .close-btn{
            height: fit-content;
        }
    }
}