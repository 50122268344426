.navbar {
    width: 277px;
    height: 100vh;
    min-width: 200px;
    background-color: #FFF4E3;
    flex-flow: column !important;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    box-shadow: rgb(0 0 0 / 20%) 0 3px 3px -2px, rgb(0 0 0 / 14%) 0 3px 4px 0, rgb(0 0 0 / 12%) 0 1px 8px 0 !important;

    .navbar-brand {
        padding: 0.5rem 0;
    }

    .logo {
        width: 145px;
        height: 56px;
    }

    .navbar-nav {
        height: 100%;
        flex-flow: column;
        display: inline-block;
        flex-direction: column !important;

        .nav-link {
            padding: 1rem 0;

            .icon {
                width: 20px;
            }

            .nav-left-icon {
                margin-right: 5px;
            }

            .nav-right-icon {
                margin-left: 5px;
            }
        
            .list-not-expanded {
                transform: rotate(270deg);
            }
        }
    }
}

ul {
    list-style-type: none;
}