.review-history {

    .review-history-list-container {
        padding: 2rem 0;
    }
}

.review-detail {

    .form-btn {
        right: 2.5%;
        display: flex;
        bottom: 2.5rem;
        position: absolute;

        .review-back-btn {
            margin-right: 1rem;

            .heystack_btn {
                color: var(--black);
                background: var(--gray3);
            }
        }

        .review-reject-btn {
            margin-left: 1rem;

            .heystack_btn {
                color: var(--black);
                background: var(--gray1);
                border: 1px solid #BBBBBB;
            }
        }
    }
}