.list-card-detail{

    .card-detail-container{
        padding-top: 1rem;

        .detail-subheader{
            min-width: 7%;
            font-size: 19px;
            font-weight: 500;
            margin-right: 1rem;
            color: var(--black);
        }

        .detail-string{
            display: flex;
        }

        .detail-textarea{
            padding: 1rem 0;
        }
    }
}