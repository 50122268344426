.header {
    display: flex;
    justify-content: space-between;

    .login {
        display: flex;
        height: fit-content;
    }

    .login p {
        padding: 0 1rem;
        margin-top: auto;
        margin-bottom: auto;
    }

    .login img {
        height: fit-content;
        margin-top: auto;
        margin-bottom: auto;
    }

    .header-action-container{
        position: relative;
    }

    .header-dropdown-item {
        z-index: 1;
        padding: 0.5rem;
        cursor: pointer;
        text-align: center;
        position: absolute;
        width: -webkit-fill-available;
        background-color: var(--gray);
    }
}