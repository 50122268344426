.blog {
    height: 100%;
    position: relative;

    .blog-button {
        top: 17px;
        left: 215px;
        position: absolute;
    }

    .blog-post-container {
        height: 80vh;
        margin: 1rem 0;
        overflow: auto;
    }
}

.blogTemplate {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .blog-preview {
        width: 50%;
    }

    .blog-form {
        width: 50%;

        .form-group {
            padding-bottom: 1rem;
        }
    }

    .heystack-blogCard {
        width: 70%;
    }
}

.form-btn-container {
    display: flex;

    &.position-bottom {
        right: 2.5%;
        bottom: 2.5rem;
        position: absolute;
    }

    .form-btn:not(:last-child) {
        margin-right: 1rem;
    }

    .blog-gray-btn {
        .heystack_btn {
            color: var(--black);
            background: var(--gray3);
        }
    }
}

.blog-section {
    .blog-section-content {
        display: flex;
        height: 85vh;

        .blog-scrollable {
            flex: 1;

            .scrollable-container {
                width: 70%;
                padding: 2rem 2.5rem;
                overflow: auto;
                max-height: 80vh;
                background-color: #F3F3F3;

                .section-item:not(:last-child) {
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
}

.section-item {
    .section-item-header {
        color: white;
        width: fit-content;
        padding: 0.5rem 2rem;
        margin: 0 !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: var(--black);
    }

    .section-form {
        padding: 2.5rem 2rem;
        background-color: white;

        .form-group:not(:last-child) {
            padding-bottom: 1rem;
        }

        .select-input{
            position: relative;

            .select-icon {
                top: 50%;
                right: 15px;
                position: absolute;
            }
        }
    }

    &.section-item-container:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    .form-btn-container {
        justify-content: end;
        margin-top: 1rem;
    }
}

.select-blog-sections {
    .sections-content {
        display: flex;
        justify-content: space-between;

        .section-item {
            width: 50%;
        }
    }

    .section-template-container {
        width: 35%;
        display: grid;
        row-gap: 20px;
        column-gap: 20px;
        margin: 4vh 0 8vh 0;
        grid-template-columns: repeat(2, 1fr);

        .section-template {
            cursor: pointer;

            .active {
                border: 3px solid var(--orange3);
                border-radius: 10px;
            }

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .section-template-container {
        grid-template-columns: 1fr;
    }
}